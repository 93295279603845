// FileUpload.tsx
import React, { useState } from 'react';
import axios from '../../axios';
import { isAxiosError } from 'axios';

export interface FileUploadProps {
    refresh: React.Dispatch<React.SetStateAction<number>>;
  }

const FileUpload: React.FC<FileUploadProps> = ({refresh}) => {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFiles(event.target.files);
        }
    };

    const handleUpload = async () => {
        if (selectedFiles) {
            const formData = new FormData();
            Array.from(selectedFiles).forEach((file) => {
                formData.append('file[]', file);
            });

            try {
                const response = await axios.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                  setTimeout(() => {
                // You can do something here if needed after 5 seconds delay
                refresh(oldKey => oldKey + 1); // Update the refreshKey state to a new value to re-render AlarmList
               
                // setShowModal(false); // Close the modal
                }, 5000); // Set the delay here as 5000 milliseconds
                console.log(response.data); // handle server response
                console.log('Files uploaded successfully');
            } catch (error) {
                if (isAxiosError(error)) {
                    alert('Error uploading files: ' + error.message);
                } else {
                    alert('An unexpected error occurred');
                }
            }
        } else {
            alert('No files selected.');
        }
    };

    return (
        <div>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload</button>
        </div>
    );
};

export default FileUpload;
