import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import styles from './LogModal.module.css'; // Make sure to create this new CSS module
import axios from '../../axios';

interface LogEntry {
  id: number;
  level: string;
  message: string;
  timestamp: string;
}

const LogModal: React.FC = () => {
  const defaultCountTime=3
  const [showModal, setShowModal] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);  // Default to true for initial load
  const [countdown, setCountdown] = useState(defaultCountTime); // Assuming a 10-second countdown

  const handleClose = () => {
    setShowModal(false);
  };

  async function fetchLogs() {
    setIsLoading(true);
    try {
        const res = await axios.get('/get_log_history');
        const data = await res.data;
        data.reverse();
        setLogs(data);
        setCountdown(defaultCountTime); // Reset countdown after fetching
    } catch (error) {
        console.error('Failed to load logs:', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchLogs(); // Initial fetch when the component mounts
  }, []);

  useEffect(() => {
    let intervalId: number | undefined;
    if (showModal) {
      intervalId = window.setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        } else {
          fetchLogs();
        }
      }, 1000); // Refresh countdown every 1 second
    } else {
      setCountdown(defaultCountTime); // Reset countdown when modal is closed
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on component unmount or when modal hides
      }
    };
  }, [showModal, countdown]); // React on showModal and countdown changes


  const getLogClass = (message: string) => {
    if (message.includes('ERROR')) return styles.error;
    if (message.includes('WARNING')) return styles.warning;
    if (message.includes('INFO')) return styles.info;
    return '';
  };

  return (
    <>
      <div>
        <Button label="Logy" onClick={() => setShowModal(true)} />

        <Modal show={showModal} onClose={handleClose}>
          <Button label={`Obnovit (${countdown})`} onClick={fetchLogs} />
          <div style={{ display: 'block'}}>
            <h2>Log History</h2>
            {isLoading && logs.length === 0 ? <p>Loading...</p> : (
              <ul className={styles.list} style={{ overflowY: "scroll" }}>
                {logs.map(log => (
                  <li key={log.id}>
                    [{new Date(log.timestamp).toLocaleString()}] - <span className={`${getLogClass(log.level)}`}>{log.level}</span>: {log.message}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LogModal;