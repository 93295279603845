// axios.js or axios.ts if you're using TypeScript
import axios from 'axios';
import Cookies from 'js-cookie';

// Set the base URL for all Axios requests
const baseURL = process.env.REACT_APP_API_URL || 'https://udirna.creapy.cz/api';


const instance = axios.create({
  baseURL: baseURL, // you can include other global settings here
  // ... other global Axios configurations if needed
});

export const setAxiosAuth = (username, password) => {
  const token = btoa(`${username}:${password}`);
  instance.defaults.headers.common['Authorization'] = `Basic ${token}`;
};

export const clearAxiosAuth = () => {
  delete instance.defaults.headers.common['Authorization'];
  Cookies.remove('username');
  Cookies.remove('password');
};

export const initializeAxiosAuth = () => {
  const username = Cookies.get('username');
  const password = Cookies.get('password');
  if (username && password) {
    setAxiosAuth(username, password);
  }
};

// Export the customized instance instead of the default Axios
export default instance;