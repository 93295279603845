import { useState } from 'react';
import styles from './DashboardPage.module.css';
import Graph from '../../components/Graph/Graph';
import ToggleButton from '../../components/ToggleButton/ToggleButton';
import AddWifiModal from '../../modals/AddWifiModal/AddWifiModal';
import AddAlarmModal from '../../modals/AddAlarmModal/AddAlarmModal';
import SetTempModal from '../../modals/SetTempModal/SetTempModal';
import OtaModal from '../../modals/OtaModal/OtaModal';
import { useAuth } from '../../contexts/AuthContext';
import LogModal from '../../modals/LogModal/LogModal';
import PermissionCheck from '../../components/PermissionCheck/PermissionCheck';

function App() {
  const [isToggled, setIsToggled] = useState(false);
  const {online, ignoreOffline,setIgnoreOffline} = useAuth()
  // This is just a handler example. You will have to implement real functionality.
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

    // The cleanup function to release the wake lock when component unmounts

  return (
     <div className={`${styles.App}  ${(!online && ignoreOffline) && styles.offline }`}>
      { (!online && ignoreOffline) && <div className={styles.offlineMessage}>
     udirna je offline
      </div> }
     { (!online && !ignoreOffline) && <div className={styles.offlineBox}>
     Tato údírna už dlouho neposlala data, pravděpodobně je offline.
     <button className={styles.closeButton} onClick={()=>{setIgnoreOffline(true)}}>ignorovat</button>
      </div> }
      <header className="App-header">
        <div className={styles.ModulesContainer}>
          <div className={styles.ModalsCcontainer}>
            <AddAlarmModal></AddAlarmModal>
            <AddWifiModal></AddWifiModal>
            <PermissionCheck level={0}><OtaModal></OtaModal></PermissionCheck>
            <PermissionCheck level={0}><LogModal></LogModal></PermissionCheck>
          </div>

          <Graph />
          <div className={styles.RelaysContainer}>
            <ToggleButton name="ventilator" onToggle={handleToggle} />
            <ToggleButton name="spirala" onToggle={handleToggle} />
            <ToggleButton name="udirna" onToggle={handleToggle} />
          </div>
          <SetTempModal></SetTempModal>
        </div>
      </header>
    </div>

  );
}

export default App;
