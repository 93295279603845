import React, { useEffect, useState, useCallback } from 'react';
import axios from '../../axios';

const WifiList: React.FC = () => {

  const [wifiList, setWifiList] = useState([]);
  const [wifiConnected, setWifiConnected] = useState([]);
  const [wifiSignal, setWifiSignal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const req = useCallback(() => {
    axios.get('/get_wifi_data')
      .then(response => {
        setWifiList(response.data['list']); // Set data from axios response
        setWifiConnected(response.data['connected'])
        setWifiSignal(convertRSSItoPercentage(parseInt(response.data['signal']), -100, -20))
      })
      .catch(error => {
        setError(error.message); // Catch and set error message
      })
      .then(() => {
        setIsLoading(false); // Set loading false in both cases
      });
  }, []); // Add any dependencies that would cause req to need to be re-created

  useEffect(() => {
    req();
  }, [req]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching data: {error}</p>;
  }

  function convertRSSItoPercentage(rssi: number, minRSSI: number, maxRSSI: number): number {
    // Validation and conversion logic
    if (isNaN(rssi) || isNaN(minRSSI) || isNaN(maxRSSI) || minRSSI > maxRSSI) {
      return 0;
    }
    const percentage = ((rssi - minRSSI) / (maxRSSI - minRSSI)) * 100;
    return Math.min(Math.max(percentage, 0), 100);
  }

  return (
    <div>
      <h1>wifi uložené v udírně</h1>
      <ul>
        {wifiList && wifiList.map((wifi) => {
          const isConnected = wifi === wifiConnected;
          return (
            <li key={wifi} style={isConnected ? { fontWeight: 'bold' } : {}}>
              {wifi} {isConnected && `(připojeno, signál: ${wifiSignal}%)`}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WifiList;