import React from 'react';
import { useAuth } from '../../contexts/AuthContext';

// Define the types for your props
interface PermissionCheckProps {
  level: number;
  children: React.ReactNode;
}

const PermissionCheck: React.FC<PermissionCheckProps> = (props) => {
  const { permissionLevel } = useAuth();
  return (
    // Use curly braces to evaluate expressions and pass props correctly
    (permissionLevel <= props.level) ? <>
      {props.children}
    </> : <></>
  );
};

// Export the component, not a variable
export default PermissionCheck;