import React, { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import axios from '../../axios';
import FileUpload from '../../components/FileUpload/FileUpload';
import FileList from '../../components/FileList/FileList';



  interface FileItem {
    name: string;
    url: string;
  }

const OtaModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const [refreshKey, setRefreshKey] = useState(0); // add this line
  const [fileList, setFileList] = useState<FileItem[]>([]); // Define state with specific type

  const handleClose = () => {
    setShowModal(false);
  };


  

  const handleSubmit = async () => {
    try {
      // Prepare the data to be sent in the format required by the endpoint
      const postData = {command:"ota_update",command_args:fileList};

      // Axios POST request
      const response = await axios.post('/add_command', postData);
      console.log(response.data);
      
      // You can do something with the response here, like showing a success message

    } catch (error) {
      // Handle errors here, such as displaying a notification to the user
      console.error("Error submitting form: ", error);
    }
    
    setTimeout(() => {
      // You can do something here if needed after 5 seconds delay
      setRefreshKey(oldKey => oldKey + 1); // Update the refreshKey state to a new value to re-render AlarmList
      // setShowModal(false); // Close the modal
    }, 10); // Set the delay here as 5000 milliseconds

};


const handleSubmitDelete = async () => {
  try {
    // Prepare the data to be sent in the format required by the endpoint


    // Axios POST request
    const response = await axios.post('/clean_uploads');
    console.log(response.data);
    
    // You can do something with the response here, like showing a success message

  } catch (error) {
    // Handle errors here, such as displaying a notification to the user
    console.error("Error submitting form: ", error);
  }
  setTimeout(() => {
    // You can do something here if needed after 5 seconds delay
    setRefreshKey(oldKey => oldKey + 1); // Update the refreshKey state to a new value to re-render AlarmList
    // setShowModal(false); // Close the modal
  }, 3000); // Set the delay here as 5000 milliseconds
};

  return (
    <>
    <div>
    <Button label="OTA update" onClick={() => setShowModal(true)}></Button>
      
      <Modal show={showModal} onClose={handleClose}>
      <Button label="odeslat do udirny" onClick={() => handleSubmit()} disabled={fileList.length === 0}></Button>
        <Button label="smazat data ze serveru" onClick={() => handleSubmitDelete()} disabled={fileList.length === 0}></Button>

        <FileUpload refresh={setRefreshKey}></FileUpload>
        <FileList key={refreshKey} setFileList={setFileList}></FileList>
              </Modal>
    </div>
    </>
  );
};

export default OtaModal;