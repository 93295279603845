import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import styles from './AddAlarmModal.module.css'; // Make sure to create this new CSS module
import axios from '../../axios';
import AlarmList from '../../components/AlarmList/AlarmList';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import TimeDate from '../../components/TimeDate/TimeDate';
import { useAuth } from '../../contexts/AuthContext';

interface FormValues {
  time: string;
  target_temperature: number;
  ventilator: boolean;
  vypnout: boolean;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const AddAlarmModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // add this line
  const [date, setDate] = useState<Value>(new Date());
  const [formateddate, setFormateddate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const {datetime} = useAuth()
  
  const handleClose = () => {
    setShowModal(false);
  };

  const handleDateChange = (newValue: any) => {
    setDate(newValue);
    const formattedDate = format(newValue, 'yyyy-MM-dd');
    setFormateddate(formattedDate)
  };


  const ValidationSchema = Yup.object().shape({
    time: Yup.string()
      .required('Čas je povinný')
      .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Čas musí být ve formátu HH:mm'),
    target_temperature: Yup.number()
      .required('Cílová teplota je povinná')
      .typeError('Cílová teplota musí být číslo'),
      ventilator: Yup.boolean(),
      vypnout: Yup.boolean(),
  });

  const initialValues: FormValues = {
    time: '',
    target_temperature: 0,
    ventilator: false,
    vypnout: false
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      // Prepare the data to be sent in the format required by the endpoint
      const postData = {
        command: "set_alarm_specific",
        command_args: {
          time: values.time,
          date: formateddate,
          target_temperature: values.target_temperature,
          ventilator: values.ventilator,
          vypnout: values.vypnout
        }
      };

      // Axios POST request
      const response = await axios.post('/add_command', postData);
      console.log(response.data);

      // You can do something with the response here, such as showing a success message
    } catch (error) {
      console.error("Error submitting form: ", error);
    }

    setTimeout(() => {
      // You can do something here if needed after 5 seconds delay
      setRefreshKey(oldKey => oldKey + 1); // Update the refreshKey state to a new value to re-render AlarmList
      setSubmitting(false); // Set submitting to false after the request and timer are finished
      // setShowModal(false); // Close the modal
    }, 5000); // Set the delay here as 5000 milliseconds

  };

  
  return (
    <>
      <div>
        <Button label="Budiky" onClick={() => setShowModal(true)} />

        <Modal show={showModal} onClose={handleClose}>
        <button className={styles.Button} onClick={() => setShowAddForm(oldValue => !oldValue)} >
          pridat budik
        </button>
        { showAddForm && (
          <Formik
            
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values }) => ( // Here, you get access to the values directly
              <Form className={styles.modalContainer}>
                <TimeDate data={datetime} ></TimeDate>
                <Calendar onActiveStartDateChange={handleDateChange} onChange={handleDateChange} locale="cs" value={date} />
                <div className={styles.formGroup}>
                  <label htmlFor="time" className={styles.label}>Cas</label>
                  <Field type="text" name="time" placeholder="HH:mm" className={styles.input}  />
                  <ErrorMessage name="time" component="div" className={styles.errorMessage} />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="target_temperature" className={styles.label}>Cilova teplota</label>
                  <Field type="number" name="target_temperature" className={styles.input} disabled={values.vypnout} />
                  <ErrorMessage name="target_temperature" component="div" className={styles.errorMessage} />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="ventilator" className={styles.label}>Ventilator</label>
                  <Field type="checkbox" name="ventilator" disabled={values.vypnout} />
                  <ErrorMessage name="ventilator" component="div" className={styles.errorMessage} />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="vypnout" className={styles.label}>Vypnout udirnu</label>
                  <Field type="checkbox" name="vypnout" />
                  <ErrorMessage name="vypnout" component="div" className={styles.errorMessage} />
                </div>
                <button type="submit" disabled={isSubmitting } className={styles.submitButton}>
                  Pridat budik
                </button>
              </Form>
            )}
          </Formik> )}
          <AlarmList key={refreshKey}></AlarmList>
        </Modal>
      </div>
    </>
  );

};

export default AddAlarmModal;