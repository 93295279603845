import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import styles from './AddWifiModal.module.css'
import axios from '../../axios';
import WifiList from '../../components/WifiList/WifiList';

interface FormValues {
    ssid: string;
    password: string;
  }

const AddWifiModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // add this line

  const handleClose = () => {
    setShowModal(false);
  };

  const ValidationSchema = Yup.object().shape({
    ssid: Yup.string().required('jmeno wifi je povinne'),
    password: Yup.string().required('heslo k wifi je povinne'),
  });
  
  const initialValues: FormValues = {
    ssid: '',
    password: '',
  };
  

      const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
          // Prepare the data to be sent in the format required by the endpoint
          const postData = {
            command: "add_wifi",
            command_args: {
              ssid: values.ssid,
              password: values.password
            }
          };
    
          // Axios POST request
          const response = await axios.post('/add_command', postData);
          console.log(response.data);
          
          // You can do something with the response here, like showing a success message
    
        } catch (error) {
          // Handle errors here, such as displaying a notification to the user
          console.error("Error submitting form: ", error);
        }
        setTimeout(() => {
          // You can do something here if needed after 5 seconds delay
          setRefreshKey(oldKey => oldKey + 1); // Update the refreshKey state to a new value to re-render AlarmList
          setSubmitting(false); // Set submitting to false after the request and timer are finished
          // setShowModal(false); // Close the modal
        }, 5000); // Set the delay here as 5000 milliseconds
    };

  return (
    <>
    <div>
    <Button label="Wifi" onClick={() => setShowModal(true)}></Button>
      
      <Modal show={showModal} onClose={handleClose}>
      <button className={styles.Button} onClick={() => setShowAddForm(oldValue => !oldValue)} >
          pridat wifi
        </button>
        { showAddForm && (
      <Formik
  initialValues={initialValues}
  validationSchema={ValidationSchema}
  onSubmit={handleSubmit}
>
  {({ isSubmitting }) => (
    <Form className={styles.modalContainer}>
      <div className={styles.formGroup}>
        <label htmlFor="ssid" className={styles.label}>nazev wifi</label>
        <Field type="text" name="ssid" className={styles.input} />
        <ErrorMessage name="ssid" component="div" className={styles.errorMessage} />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="password" className={styles.label}>Password</label>
        <Field type="password" name="password" className={styles.input} />
        <ErrorMessage name="password" component="div" className={styles.errorMessage} />
      </div>
      <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
        Pridat
      </button>
    </Form>
  )}
</Formik>)}

      <WifiList key={refreshKey}></WifiList>
      </Modal>
    </div>
    </>
  );
};

export default AddWifiModal;