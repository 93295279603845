import React from 'react';
import { useAuth } from './contexts/AuthContext';
import LoginPage from './pages/LoginPage/LoginPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';

const App: React.FC = () => {
  const { auth } = useAuth();

  return (
    <div>
      {auth.username ? <DashboardPage /> : <LoginPage />}
    </div>
  );
};

export default App;