// FileList.tsx
import React, { useState, useEffect } from 'react';
import axios from '../../axios';
import { isAxiosError } from 'axios';

interface FileItem {
  name: string;
  url: string;

}

interface FileListprops {
    setFileList: ((files: FileItem[]) => void);
  }

const FileList: React.FC<FileListprops> = ({setFileList}) => {
    const [files, setFiles] = useState<FileItem[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('/files');
                setFiles(response.data);
                setFileList(response.data)
            } catch (error) {
                if (isAxiosError(error)) {
                    setError(error.message);
                } else {
                    setError('An unexpected error occurred');
                }
            }
        };

        fetchFiles();
    }, [setFileList]);

    return (
        <div>
            <h2>Available Files</h2>
            {error && <p>Error fetching files: {error}</p>}
            <ul>
                {files.map(file => (
                    <li key={file.name}>
                        {file.name} - <a href={file.url} target="_blank" rel="noopener noreferrer">Download</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FileList;