import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import styles from './AlarmList.module.css'; 

interface AlarmListprops {
}

const AlarmList: React.FC<AlarmListprops> = () => {

  const [alarmList, setAlarmList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  const req = () => {
    axios.get('/get_alarm_data')
    .then(response => {
      setAlarmList(response.data); // Set data from axios response
    })
    .catch(error => {
      setError(error.message); // Catch and set error message
    })
    .then(() => {
      setIsLoading(false); // Set loading false in both cases
    });
  }

  function addLeadingZeros(n: number): string {
    return n < 10 ? `0${n}` : n.toString();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      req();
    }, 1000); // 2000 milliseconds = 2 seconds
  
    return () => clearInterval(interval); // This clears the interval when the component unmounts
  }, []);

    if (isLoading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error fetching data: {error}</p>;
    }
    return (
      <div className={styles.tableContainer}>
       { alarmList.length === 0 ?  
        <h1>v udirne nejsou ulozene zadne budiky</h1>
        :
        <>
        <h1>budiky ulozene v udirne</h1>
        <table className={styles.table}>
          <thead>
            <tr>
              <th scope="col" className={styles.th}>datum</th>
              <th scope="col" className={styles.th}>cas</th>
              <th scope="col" className={styles.th}>cilova_teplota</th>
              <th scope="col" className={styles.th}>ventilator</th>
              <th scope="col" className={styles.th}>vypnuti</th>
            </tr>
          </thead>
          <tbody>
            {alarmList && alarmList.map((alarm, index) => {
              const ventilatorStatus = alarm['vypnout'] ? "-----" : (alarm['ventilator'] ? "Zapnout" : "Vypnout");
              return (
                <tr key={index} className={alarm['vypnout'] ? styles.trDisabled : styles.trEnabled}>
                  <td data-label="datum" className={styles.td}>{alarm['day']}.{alarm['month']}.{alarm['year']}</td>
                  <td data-label="cas" className={styles.td}>{addLeadingZeros(alarm['hour'])}:{addLeadingZeros(alarm['min'])}</td>
                  <td data-label="cilova_teplota" className={styles.td}>{alarm['vypnout'] ? "-----" : `${alarm['teplota']}°C`}</td>
                  <td data-label="ventilator" className={styles.td}>{ventilatorStatus}</td>
                  <td data-label="vypnuti" className={styles.td}>{alarm['vypnout'] ? "Ano" : "Ne"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </>}
      </div>
    );
    
};

export default AlarmList;