import React from 'react';

interface Time {
  day: number;
  hour: number;
  min: number;
  month: number;
  year: number;
}


const TimeDate: React.FC<{ data: Time }> = ({ data }) => {
  const { day, month, year, hour, min } = data;

  // Format the date and time as a string
  const formattedDate = `${day}.${month}.${year}`;
  const formattedTime = `${hour}:${min < 10 ? '0' + min : min}`;
  return (
      <div>
          <big>Datum a čas v udirně</big>
          <div>Datum: {formattedDate}</div>
          <div>Čas: {formattedTime}</div>
      </div>
  );
};
export default TimeDate;