// src/pages/LoginPage/LoginPage.tsx
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styles from './LoginPage.module.css'; // Import the styles

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const authContext = useAuth();

  const handleLogin = async () => {
    if (authContext) {
      await authContext.login(username, password);
    }
  };

  return (
    <div className={styles.container}>
      <img className={styles.logo} alt= "logo " src='logo512.png'></img>
      
      <div className={styles.loginForm}>
      <h1>Udírna 1.0</h1>
        <input
          className={styles.inputField}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Jmeno"
        />
        <input
          className={styles.inputField}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Heslo"
        />
        <button className={styles.loginButton} onClick={handleLogin}>
          Přihlásit
        </button>
      </div>
    </div>
  );
};

export default LoginPage;