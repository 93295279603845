import React, { createContext, useState, ReactNode, useContext, useEffect, useCallback } from 'react';
import axios from '../axios';
import Cookies from 'js-cookie';
import { setAxiosAuth } from '../axios';

interface AuthState {
  username: string;
  password: string;
  isLoggedIn: boolean;
}

interface Time {
  day: number;
  hour: number;
  min: number;
  month: number;
  year: number;
}

export const AuthContext = createContext<{
  auth: AuthState;
  status: any;
  setAuth: React.Dispatch<React.SetStateAction<AuthState>>;
  setStatus: React.Dispatch<React.SetStateAction<any>>;
  login: (username: string, password: string) => Promise<void>;
  fetchStatus: () => Promise<void>;
  permissionLevel: number;
  setPermissionLevel: React.Dispatch<React.SetStateAction<number>>;
  online: boolean;
  setOnline: React.Dispatch<React.SetStateAction<boolean>>;
  datetime: Time;
  setdatetime: React.Dispatch<React.SetStateAction<Time>>;
  ignoreOffline: boolean;
  setIgnoreOffline: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>({ username: '', password: '', isLoggedIn: false });
  const [status, setStatus] = useState<any>({});
  const [datetime, setdatetime] = useState<any>({});
  const [permissionLevel, setPermissionLevel] = useState<number>(10);
  const [online, setOnline] = useState<boolean>(true);
  const [ignoreOffline, setIgnoreOffline] = useState<boolean>(false);

  // Use useCallback to wrap login
  const login = useCallback(async (username: string, password: string) => {
    try {
      const response = await axios.post('/login', {}, {
        auth: {
          username: username,
          password: password,
        },
      });
      if (response.status === 200) {
        setAuth({ username, password, isLoggedIn: true });
        setPermissionLevel(response.data.level)


        setAxiosAuth(username, password);
        Cookies.set('username', username, { expires: 7 });
        Cookies.set('password', password, { expires: 7 });
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  }, []); // Dependencies should be added if they are from outside the callback

  const fetchStatus = useCallback(async () => {
    try {
      const response = await axios.get('/current_status', {
        auth: {
          username: auth.username,
          password: auth.password,
        },
      });
      setStatus(response.data.status);
      setdatetime(response.data.time)
    } catch (error) {
      console.error('Failed to fetch status:', error);
    }
  }, [auth.username, auth.password]);

  useEffect(() => {
    const cookieUsername = Cookies.get('username');
    const cookiePassword = Cookies.get('password');
    if (cookieUsername && cookiePassword && !auth.isLoggedIn) {
      login(cookieUsername, cookiePassword);
    }
    if (auth.isLoggedIn) {
      const intervalId = setInterval(fetchStatus, 1000);
      return () => clearInterval(intervalId);
    }
  }, [auth.isLoggedIn, fetchStatus, login]);

  return (
    <AuthContext.Provider value={{
      auth, status, setAuth, setStatus, login, fetchStatus, online, setOnline, ignoreOffline, setIgnoreOffline,setdatetime,datetime, permissionLevel, setPermissionLevel
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};