import React from 'react';
import styles from './TempDisplay.module.css';
import { useAuth } from '../../contexts/AuthContext';

interface TempDisplayprops {
  onClick: (() => void) | undefined;
}

const TempDisplay: React.FC<TempDisplayprops> = ({onClick}) => {

  const {status} = useAuth()

  const formatTemperature = (temp: any): string => {
    // Check if 'temp' is a number and is not undefined
    const number = parseFloat(temp);
    return !isNaN(number) ? number.toFixed(2) : 'N/A';
  };

  const currentTemp = formatTemperature(status['temp']);

  return (
    <span className={styles.TempDisplay} onClick={onClick}>
      {currentTemp}/{status['target_temp']}<small>°C</small>     </span>
  );
};


export default TempDisplay;