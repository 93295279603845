import React, { useEffect, useState } from 'react';
import styles from './ToggleButton.module.css';
import axios from '../../axios';
import { useAuth } from '../../contexts/AuthContext';


interface ToggleButtonProps {
  name: string;
  onToggle: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ name, onToggle }) => {

    const { status } = useAuth();
    const [isEnabled,setIsEnabled] =  useState(false)

    const [available,setIsAvailable] =  useState(true)

    const toggle = async() => {
        // setIsEnabled((prev) => !prev)
        try {
          const postData = {
            command: "set_relay",
            command_args: {
              relay: name,
              value: !isEnabled
            }
          };
    
          await axios.post('/add_command', postData);
        } catch (error) {
          console.error("Error submitting form: ", error);
        }

    };

    

    useEffect(() => {
        // Ensure the name exists within the nested status object before trying to set state
        if (status && name in status) {
            setIsAvailable(true)
            setIsEnabled(status[name]);
  
        }
        else{
            setIsAvailable(false)
        }
    }, [status, name, available]); // React to changes in status or name


  return (
    <button onClick={toggle} className={`${styles.toggleButton} ${available ? isEnabled ? styles.on : styles.off : styles.unavailable}`}>
        {available ? isEnabled ? `${name} ON` : `${name} OFF`: `${name} nedostupna`}
      
    </button>
  );
};

export default ToggleButton;