import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import axios from '../../axios';
import styles from './Graph.module.css';
import { useAuth } from '../../contexts/AuthContext';

// Define types for your data
interface TemperatureHistoryEntry {
  id: number;
  value: number;
  heating: boolean;
  timestamp: string;
}

interface GraphData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string;
    borderColor: string;
  }[];
}

const Graph: React.FC = () => {
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const {auth, setOnline, setIgnoreOffline} = useAuth();


  function isOlderThan(timestamp: string, minutes: number): boolean {
    const pastTime = new Date(timestamp);
    const currentTime = new Date();
    const differenceInMinutes = (currentTime.getTime() - pastTime.getTime()) / (1000 * 60);
    // console.log(`${timestamp}: ${differenceInMinutes} > ${minutes}`)
    return differenceInMinutes > minutes;
}

  useEffect(() => {
    const intervalId = setInterval(() => {
      const fetchTemperatureHistory = async () => {
        try {
          const response = await axios.get<TemperatureHistoryEntry[]>('/get_temperature_history', {
            auth: {
              username: auth.username,
              password: auth.password,
            },
          });
          const data = response.data;
          if (isOlderThan(data[data.length-1]["timestamp"], 6)){
            setOnline(false)
          }else{
            setOnline(true)
            setIgnoreOffline(false)
          }

          // setStatus(prevStatus => ({
          //   ...prevStatus,
          //   temp: data[data.length - 1].value
            
          // }));
          const transformedData: GraphData = {
            labels: data.map(entry => new Date(entry.timestamp).toLocaleTimeString('cs-CZ', { hour12: false })),
            datasets: [
              {
                label: 'Temperature',
                data: data.map(entry => entry.value),
                fill: false,
                backgroundColor: data.map(entry => entry.heating ? 'rgba(255, 99, 132, 0.2)' : 'rgba(75,192,192,0.2)') as any, 
                borderColor: data.map(entry => entry.heating ? 'rgba(255, 99, 132, 1)' : 'rgba(75,192,192,1)') as any

              }
            ],
          };

          setGraphData(transformedData);
        } catch (error) {
          console.error("Error fetching temperature history: ", error);
        }
      };

      fetchTemperatureHistory();
    }, 4000);

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [auth.username, auth.password, setIgnoreOffline, setOnline]); // Only re-run the effect if auth credentials change

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className={styles.graphContainer}>
      {graphData ? (
        <Line data={graphData} options={options} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Graph;