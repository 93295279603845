import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../components/Modal/Modal';
import styles from './SetTempModal.module.css';
import axios from '../../axios';
import TempDisplay from '../../components/TempDisplay/TempDisplay';

interface SetTempFormValues {
  target_temp: string;
}



const SetTempModal: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const initialValues: SetTempFormValues = {
    target_temp: '',
  };

  const validationSchema = Yup.object().shape({
    target_temp: Yup.number()
      .required('Teplota musi byt zadana')
      .min(-5, "Teplota nemuze byt mensi nez -5"),
  });

  const handleSubmit = async (values: SetTempFormValues, { setSubmitting }: FormikHelpers<SetTempFormValues>) => {
    try {
      const postData = {
        command: "set_target_temp",
        command_args: {
          target_temp: values.target_temp
        }
      };

      await axios.post('/add_command', postData);
    } catch (error) {
      console.error("Error submitting form: ", error);
    } finally {
      setSubmitting(false);
      setShowModal(false);
    }
  };

  return (
    <div>
      <TempDisplay onClick={() => setShowModal(true)}></TempDisplay>
      <Modal show={showModal} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.modalContainer}>
              <div className={styles.formGroup}>
                <label htmlFor="target_temp" className={styles.label}>Cilova teplota</label>
                <Field type="number" name="target_temp" className={styles.input} />
                <ErrorMessage name="target_temp" component="div" className={styles.errorMessage} />
              </div>
              <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
                Nastavit teplotu
              </button>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default SetTempModal;